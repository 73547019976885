import React from "react";
import data from "../../constants/data";
import images from "../../constants/images";
import SubHeading from "../../components/SubHeading/SubHeading";
import { useSelector } from "react-redux";
import "./Header.css";

const Header = () => {
  const { language } = useSelector((state) => state.settings);
  return (
    <>
      <div className="app__header app__wrapper section__padding" id="home">
        <div className="app__wrapper_i">
          {/* <SubHeading /> */}
          <h1 className="app__header-h1" data-aos="fade-left">
            {data.header.title[language]}
          </h1>
          <p
            className="p__opensans"
            style={{ marginBottom: "2rem" }}
            data-aos="fade-right"
          >
            {data.header.description[language]}
          </p>
          <button type="button" className="custom__button">
            <a href="tel://+381641494033">
              {language === "En" ? "Contact us" : "Kontaktirajte nas"}
            </a>
          </button>
        </div>
        <div className="app__wrapper_i licence">
          {/* <img className="licence" src={images.baka1} alt="casovi plivanja" /> */}
          <p
            className="p__opensans"
            style={{ margin: "0 0 2rem 2rem" }}
            data-aos="fade-right"
          >
            {language === "En" ? "Graduated Msc in Sport and Physical Education" : "Diplomirani Msc sporta i fizičkog vaspitanja"}
          </p>
          <p
            className="p__opensans"
            style={{ margin: "0 0 0 2rem" }}
            data-aos="fade-right"
          >
            {language === "En" ? "License and permit for the work of the Swimming Federation of Serbia no: 0333" : "Licenca i dozvola za rad Plivačkog Saveza Srbije br: 0333"}
          </p>
          <p
            className="p__opensans"
            style={{ margin: "0 0 0 2rem" }}
            data-aos="fade-right"
          >
            {language === "En" ? "License to work with children and youth issued by the Ministry of Education and Sports of the Republic of Serbia" : "Dozvola za rad sa decom i  omladinom Ministarstvo prosvete i sporta Republike Srbije"}
          </p>
        </div>
      </div>
    </>


  );
};

export default Header;
