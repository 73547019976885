import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "users",
    initialState: {
        user: {},
    },
    reducers: {
        setUser: (state, action) => {
            state.user = action.payload;
            console.log(action.payload);
        },
    },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
