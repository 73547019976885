import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import { RouterProvider } from "react-router-dom";
import router from "./routes/router";
import axios from "axios";

axios.defaults.baseURL = "https://be-casoviplivanja.casoviplivanjanovisad.com";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(

  <div>

    <React.StrictMode>
      <Provider store={store}>
        <RouterProvider router={router} />
      </Provider>
    </React.StrictMode>
  </div>

);
