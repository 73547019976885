import React from "react";
import images from "../../constants/images"

const SubHeading = ({ title }) => (
  <>
    <p className="p__cormorant">{title}</p>
    <img src={images.swimming} alt="casovi plivanja" className="swimming_img" />
  </>
);

export default SubHeading;
